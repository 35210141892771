.my-legend .legend-title {
  text-align: left;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 90%;
}
.my-legend .legend-scale ul {
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  float: left;
  list-style: none;
}
.my-legend .legend-scale ul li {
  font-size: 60%;
  list-style: none;
  margin-left: 0;
  line-height: 18px;
  margin-bottom: 2px;
}
.my-legend ul.legend-labels li span {
  display: block;
  float: left;
  height: 15px;
  width: 15px;
  margin-right: 5px;
  margin-left: 0;
  border: 1px solid #999;
}
.my-legend .legend-source {
  font-size: 80%;
  color: #999;
  clear: both;
}
.my-legend a {
  color: #777;
}
